@font-face {
  font-family: "Pretendard Variable";
  src: url("assets/images/event/241028/fonts/PretendardJPVariable.ttf");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Pretendard Variable";
  src: url("assets/images/event/241028/fonts/PretendardVariable.ttf");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GmarketSansBold';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

:root{
  --max-width: 1000px;
  --main-font: "Pretendard Variable";
  --sub-font: "GmarketSansBold";
  --font-size-title: 60px;
  --font-size-text-large: 35px;
  --font-size-text-normal: 32px;
  --praimary-color1: #F35157;
  --praimary-color2: #3DB69C;
  --bg-color1: #FFDE89;
  --bg-color2: #FABF4F;
}
*{
  font-family: var(--main-font);
}
.sectionWarp{
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 50px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.imgBox{
  width: fit-content;
}
.imgBox img{
  width: 100%;
}
.bgc1{
  background-color: var(--praimary-color1);
}
.bgc2{
  background-color: var(--praimary-color2);
}
.bgc3{
  background-color: rgba(92, 70, 65, 0.8);;
}
h1, h2, h3, h4, h5, p{
  margin: 0;
}
.mainTitleBox{
  display: flex;
  flex-flow: column;
  gap: 20px;
  margin-bottom: 50px;
}
.mainTitleBox h2{
  font-family: var(--sub-font);
  font-size: var(--font-size-title);
  color: #5C4641;
  text-align: center;
  font-weight: 700;
}
.mainTitleBox p{
  font-size: var(--font-size-text-normal);
  line-height: 38px;
  color: #937338;
  font-weight: 500;
  letter-spacing: -0.02em;
  text-align: center;
}
.EB{
  font-weight: 800;
}


/* 영문 페이지 */
/* 섹션1 */
.section1{
  background-color: var(--bg-color1);
  background-image: url(assets/images/event/241028/section1_bg1.png);
  background-repeat: repeat-x;
  background-size: auto 75%;
  padding-top: 35px;
}
.section1 .sectionWarp{
  padding: 0;
}
.section1 .sectionWarp>div:nth-child(3){
  padding: 0 50px;
  margin-bottom: 70px;
}
.section1 .videoContainer{
  height: 54vw;
  max-height: 540px;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: end;
}
.section1 .videoBox{
  width: 76.6%;
  height: 40.9vw;
  max-height: 449px;
  background-color: #DDDDDD;
  position: absolute;
  bottom: 16%;
  border: 20px solid #373737;
  border-radius: 29px;
  box-sizing: border-box;
}
.section1 .bgc1{
  margin: 32px 0;
  padding: 10.5665px 34.7184px;
  box-sizing: border-box;
  border-radius: 100px;
}
.section1 .bgc1 h1{
  color: #fff;
  font-weight: 700;
  line-height: 50px;
  font-size: 42.2658px;
}

/* 섹션2 */
.section2{
  padding-top: 80px;
}
.section2 .imgCardBox{
  width: 100%;
  max-width: 823px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;
  justify-items: center;
  column-gap: 42px;
}
.section2 .imgCard{
  max-width: 402px;
  border: 6px solid #FFC353;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}
.section2 .imgCard .imgBox img{
  height: 100%;
}
.section2 .imgCard .textBox{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--praimary-color2);
  border-radius: 0 0 24px 24px;
  font-weight: 800;
  font-size: 23.6763px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  padding: 19px 1rem;
}

/* 섹션3 */
.section3{
  padding-top: 130px;
}
.section3 .mainTitleBox{
  margin-bottom: 78px;
}
.section3 .mainTitleBox p{
  font-weight: 600;
}
.section3 .content1{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 24px;
  gap: 1rem;
}
.section3 .content1 .step{
  background-color: #fff;
  border: 4.68817px solid #FFC353;
  border-radius: 26.2538px;
  position: relative;
}
.section3 .content1 .imgBox{
  display: flex;
  align-items: center;
}
.section3 .content1 .step1{
  padding: 42px 36px;
}
.section3 .content1 .step .bgc1{
  display: inline-block;
  position: absolute;
  top: -25.39px;
  left: 24px;
  padding: 4.89922px 28.2149px;
  box-sizing: border-box;
  border-radius: 81.3583px;
  font-weight: 700;
  font-size: 34.3485px;
  line-height: 41px;
  color: #fff;
}
.section3 .content1 .step2 .bgc1{
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.section3 .content1 .step h4{
  font-weight: 900;
  font-size: 31.6123px;
  line-height: 41px;
  color: var(--praimary-color2);
  margin-bottom: 10px;
}
.section3 .content1 .step1 p:nth-of-type(1){
  font-weight: 600;
  font-size: 22.5625px;
  line-height: 33px;
  margin-bottom: 8px;
  color: #CACACA;

}
.section3 .content1 .step1 p:nth-of-type(2){
  font-weight: 500;
  font-size: 15.9567px;
  line-height: 19px;
  color: #7D7D7D;
}
.section3 .content1 .step1>div:nth-child(2){
  border-bottom: 4.33894px dashed #FFC353;
  padding-bottom: 40px;
}
.section3 .content1 .step1>div:nth-child(3){
  padding-top: 24px;
}
.section3 .content1 .step2{
  /* height: 100%; */
  padding: 56px 20px 36px 20px;
}
.section3 .content1 .step2 p,content1
.section3 .content1 .step2 h4{
  text-align: center;
}
.section3 .content1 .step2 p{
  font-weight: 600;
  font-size: 24.4961px;
  line-height: 35px;
  margin-bottom: 12px;
  color: #CACACA;

}
.section3 .content1 .step2 h4{
  margin-bottom: 54px;
}
.section3 .content1 .step2 ol{
  margin: 0;
  padding-left: 20px;
}
.section3 .content1 .step2 h4{
  line-height: 32px;
}
.section3 .content1 .step2 h5{
  font-weight: 700;
  font-size: 16.816px;
  line-height: 20px;
  color: #585858;
  margin-bottom: 8px;
}
.section3 .content1 .step2 li{
  font-weight: 500;
  font-size: 14.6976px;
  line-height: 18px;
  color: #7D7D7D;
}
.section3 .content1 .step2 li:nth-child(1){
  margin-bottom: 8px;
}

.section3 .content2{
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 26.7938px;
  padding: 32px 36px 24px 36px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  gap: 38px;
  margin-bottom: 30px;
}
.section3 .content2 h3{
  font-family: var(--sub-font);
  font-weight: 500;
  font-size: 35.4088px;
  line-height: 124.6%;
  text-align: center;
  color: #5C4641;
}
.section3 .content2>div{
  display: flex;
  gap: 30px;
  align-items: center;
}
.section3 .content2>div>div{
  display: flex;
  gap: 32px;
  align-items: center;
}
.section3 .content2 .bgc1,
.section3 .content2 .bgc3{
  padding: 6.1344px 20.1559px;
  border-radius: 58.12px;
  font-weight: 700;
  font-size: 24.5376px;
  line-height: 29px;
  color: #fff;
  white-space: nowrap;
}
.section3 .content3{
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 26.7938px;
  padding: 43px 36px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 54px;
  margin-bottom: 54px;
}
.section3 .content3 h3{
  color: #5C4641;
  font-family: var(--sub-font);
  font-size: 25.761px;
  font-weight: 500;
  line-height: 124.6%;
}
.section3 .content3 h3 span{
  color: #937338;
  font-family: var(--sub-font);
  font-size: 23.281px;
  font-style: normal;
  font-weight: 500;
  line-height: 124.6%; /* 29.008px */
}
.section3 .content3>div{
  display: flex;
  gap: 36.5px;
}
.section3 .content3>div .bgc2{
  padding: 18px 31px;
  border-radius: 8px;
  position: relative;
}
.section3 .content3>div .bgc2 a{
  color: #FFF;
  text-align: start;
  font-size: 28px;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.section3 .content3 > div .bgc2 .imgBox {
  position: absolute;
  bottom: -43px;
  right: -8px;
  animation: bounce 1s ease-in-out infinite;
}

/* 섹션3-1 */
.section3_1{
  background-color: var(--bg-color2);
  padding: 72px 0 55px 0;
}
.section3_1 .content{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: stretch;
  margin-bottom: 36px;
}
.section3_1 .content .imgBox{
  display: flex;
  align-items: center;
}
.section3_1 .content .step{
  flex-grow: 1;
  border-radius: 18.086px;
  border: 2.584px solid var(--praimary-color1);
  background: #FFF;
  position: relative;
  padding: 38.76px 15.73px 25.79px 15.73px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.section3_1 .content .step .bgc1{
  position: absolute;
  padding: 3.389px 19.517px;
  border-radius: 56.277px;
  color: #FFF;
  text-align: center;
  font-size: 23.759px;
  font-weight: 700;
  transform: translateX(-50%);
  top: -17.875px;
  left: 50%;
}
.section3_1 .content .step h5{
  color: #4C4125;
  text-align: center;
  font-size: 19.432px;
  font-weight: 600;
  line-height: 28.294px;
  margin-bottom: 14.48px;
}
.section3_1 .content .step p{
  color: #7D7D7D;
  font-size: 15.503px;
  font-weight: 600;
  text-align: center;
}
.section3_1 .textBox{
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 32.852px;
}

/* 섹션4 */
.section4{
  padding-top: 96px;
}
.section4 .mainTitleBox{
  margin-bottom: 40px;
}
.section4 .content{
  width: 100%;
  padding: 0 36px;
  display: flex;
  flex-flow: column;
  gap: 7px;
  box-sizing: border-box;
}
.section4 .content .prize{
  display: flex;
  align-items: center;
  height: 64px;
  border-radius: 48px;
  background: rgba(255, 255, 255, 0.50);
  padding: 0 54px 0 30px;
  gap: 39.12px;
}
.section4 .content .prize:last-child{
  height: 80px;
}
.section4 .content .prize>div:nth-child(2){
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  color: #4C4125;
  font-size: 30px;
  font-weight: 700;
  line-height: 32.852px;
}
.section4 .content .prize .qty{
  width: 100px;
  font-size: 26px;
}
.section4 .content .prize .bgc1{
  width: 170px;
  padding: 4.533px 0;
  border-radius: 75.275px;
  box-sizing: border-box;
  text-align: center;
  color: #FFF;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}
.section4 .content .prize:last-child .bgc1{
  border-radius: 16px;
  color: #FFF;
  font-size: 20px;
}

/* 섹션5 */
.section5{
  padding-top: 90px;
  padding-bottom: 72px;
}
.section5 .mainTitleBox{
  margin-bottom: 72px;
}
.section5 .content1{
  width: 100%;
  margin-bottom: 73px;
}
.section5 .content2{
  width: 100%;
  margin-bottom: 76px;
}
.section5 .content2 .videoBox{
  width: 100%;
  height: 503px;
  border-radius: 16px;
  background: #FFF;
}
.section5 .bgc2{
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 34px;
  padding: 12px 28px;
  border-radius: 66.321px;
  box-sizing: border-box;
  color: #FFF;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}
.section5 p{
  margin-bottom: 40px;
  color: #4C4125;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
}
.section5 .bgc1{
  width: fit-content;
  margin: 0 auto;
  padding: 10px 35px;
  border-radius: 56.277px;
  color: #FFF;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  cursor: pointer;
}
.section5 .modal{
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  background-color: #fff;
  margin-top: 40px;
  border-radius: 16px;
  border: 4.7px solid #FFC353;
  box-sizing: border-box;
}
.section5 .modal .textBox{
  display: flex;
  flex-flow: column;
  gap: 10px;
}
.section5 .modal .textBox p{
  margin-bottom: unset;
  color: #000;
  text-align: start;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
}
.section5 .modal .textBox p .span1{
  color: #227765;
  font-weight: 700;
}
.section5 .modal .textBox p .span2{
  color: #8D8D8D;
  font-size: 17px;
  font-weight: 500;
  line-height: 29px;
}
.section5 .modal .textBox p .span3{
  color: #461E00;
  font-size: 19.5px;
  font-weight: 700;
  line-height: 29px;
}
.section5 .close {
  width: fit-content;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: auto;
  padding: 8px 16px;
  border-radius: 20px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

/* 섹션6 */
.section6{
  background-color: var(--bg-color2);
  padding-top: 40px;
  padding-bottom: 54px;
}
.section6 .mainTitleBox{
  width: 100%;
  align-items: start;
  margin-bottom: 34px;
}
.section6 .mainTitleBox h2{
  font-size: 30px;
  font-weight: 800;
  line-height: 30px;
}
.section6 .content{
  width: 100%;
}
.section6 .content h3{
  color: #461E00;
  font-size: 21px;
  font-weight: 700;
  line-height: 32.852px;
}
.section6 .content h4{
  color: #77561A;
  font-size: 20px;
  font-weight: 700;
  line-height: 32.852px;
}
.section6 .content p{
  color: #77561A;
  font-size: 20px;
  font-weight: 500;
  line-height: 32.852px;
}
.section6 .content a{
  color: #77561A;
}
.section6 .content .text1{
  color: #A6771F;
  font-size: 16px;
  font-weight: 500;
}
.section6 .content .text2{
  font-size: 18px;
  font-weight: 600;
}
.section6 .content .text3{
  font-size: 18px;
}
.section6 .content>div{
  padding-top: 19px;
  padding-bottom: 16px;
  box-sizing: border-box;
}
.section6 .content>div:not(:last-child){
  border-bottom: 1.8px solid #FFDA91;
}
.section6 .content>div:first-child{
  display: flex;
  align-items: center;
  gap: 47px ;
}
.section6 .content .marginText{
  margin-bottom: 14px;
}
.section6 .content .logoBox,
.section6 .content .logoBox>div{
  display: flex;
  align-items: center;
  gap: 12px;
}

/* 중문 페이지 */
.chinese .mainTitleBox h2 {
  font-family: var(--main-font);
  font-size: 65px;
  font-weight: 800;
}
.chinese .mainTitleBox p {
  font-weight: 600;
}
.chinese .section3 .content1 .step h4 {
  font-size: 39.167px;
  line-height: 39.972px;
}
.chinese .section3 .content1 .step1 {
  flex: 1;
}
.chinese .section3 .content1 .step2 {
  width: 24%;
}
.chinese .section3 .content2 h3 {
  font-family: var(--main-font);
  font-weight: 800;
  font-size: 40px;
}
.chinese .section3 .content3 h3 {
  font-family: var(--main-font);
  font-size: 32.201px;
  font-weight: 800;
}
.chinese .section3 .content3 h3 span {
  font-family: var(--main-font);
  font-size: 29.101px;
  font-weight: 800;
}
.chinese .section4 .content .prize {
  padding: 0 12px 0 30px;
}
.chinese .section4 .content .prize>div:nth-child(2) span {
  font-size: 23px;
  font-weight: 400;
}
.chinese .section4 .content .prize .qty {
  font-size: 24px;
}
.chinese .section4 .content .prize:last-child .bgc1{
  width: 170px;
  padding: 4.533px 0;
  border-radius: 75.275px;
  box-sizing: border-box;
  text-align: center;
  color: #FFF;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}
.chinese .section4 .content .prize:last-child{
  height: 64px;
}
.chinese .section5 .bgc2 {
  font-size: 33px;
}
.chinese .section5 .bgc1 {
  font-size: 35px;
}
.chinese .section6 .mainTitleBox h2 {
  font-size: 30px;
  font-weight: 800;
  line-height: 30px;
}
.chinese .section6 .content .text4 {
  font-weight: 600;
}

/* 배너 */
.bannerContainer{
  width: 200px;
  height: 200px;
  border-radius: 141px;
  background: var(--bg-color1);
  background-image: url(assets/images/event/241028/banner_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 12.5px;
  padding-bottom: 8.19px;
  box-sizing: border-box;
  overflow: hidden;
}
.bannerContainer .bgc1{
  padding: 1.948px 6.401px;
  border-radius: 18.457px;
  margin-bottom: 7.39px;
}
.bannerContainer .bgc1 h1{
  color: #FFF;
  font-size: 7.792px;
  font-weight: 700;
}
.bannerContainer p{
  color: #937338;
  text-align: center;
  font-size: 9.538px;
  font-weight: 800;
  letter-spacing: -0.191px;
  margin-bottom: 4.5px;
}
.bannerContainer>div:nth-child(2){
  margin-bottom: 4.15px;
}


@media (max-width: 1000px) {
  .mainTitleBox{
    margin-bottom: 5vw;
  }
  .section1 .sectionWarp>div:nth-child(3){
    margin-bottom: 1vw;
  }
  .section1 .bgc1{
    margin: 3.2vw 0;
  }
  .section3 .content1{
    margin-bottom: 2.4vw;
  }
  .section3 .content2{
    margin-bottom: 3vw;
  }
  .section3 .content3{
    margin-bottom: 5.4vw;
  }
  .section4 .mainTitleBox{
    margin-bottom: 4vw;
  }
  .section5 .mainTitleBox{
    margin-bottom: 7.2vw;
  }
  .section5 .content2{
    margin-bottom: 7.6vw;
  }
  .section5 p{
    margin-bottom: 4vw;
  }
  .section3 .content3>div {
    gap: 4.75vw;
    flex-flow: column;
  }
  .section4 .content .prize>div:nth-child(2){
    font-size: 20px;
    line-height: unset;
  }
  .chinese .section4 .content .prize>div:nth-child(2) span {
    font-size: 17px;
    line-height: unset;
  }
  .section4 .content .prize .qty {
    width: 8vw;
    font-size: 18px;
  }
  .chinese .section4 .content .prize .qty {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .sectionWarp {
    padding: 0 6.5vw;
  }
  .section1 .sectionWarp>div:nth-child(1) img{
    width: 24.34vw;
  }
  .section1 .bgc1 {
    padding: 1.3vw 4.5vw;
  }
  .section1 .bgc1 h1 {
    line-height: unset;
    font-size: 5.47vw;
  }
  .section1 .videoBox {
    border: 2.6vw solid #373737;
  }
  .mainTitleBox h2 {
    font-size: 7.8vw;
  }
  .chinese .mainTitleBox h2 {
    font-size: 8.46vw;
  }
  .mainTitleBox p {
    font-size: 4.17vw;
  }
  .section2 .imgCardBox {
    grid-template-columns: repeat(1, 1fr);
  }
  .section2 .imgCard .textBox {
    font-size: 4.1vw;
    line-height: unset;
    text-align: center;
    color: #fff;
    padding: 2.47vw 1rem;
  }
  .section3 {
    padding-top: 80px;
  }
  .section3 .mainTitleBox {
    margin-bottom: 10.2vw;
  }
  .section3 .content1 {
    flex-flow: column;
    margin-top: 2vw;
  }
  .section3 .content1 .imgBox {
    margin: 0 auto;
    transform: rotate(90deg);
  }
  .section3 .content1 .step2 p, .section3 .content1 .step2 h4 {
    width: fit-content;
  }
  .section3 .content1 .step2 h4 br{
    display: none;
  }
  .section3 .content1 .step2 h4 {
    margin-bottom: 20px;
  }
  .section3 .content1 .step .bgc1 {
    padding: 0.64vw 3.75vw;
    border-radius: 10.6vw;
    font-size: 4.47vw;
  }
  .section3 .content1 .step2 .bgc1 {
    left: 24px;
    transform: translateX(0);
  }
  .chinese .section3 .content1 .step2 {
    width: 100%;
    box-sizing: border-box;
  }
  .section3 .content1 .step h4 {
    font-size: 4.1vw;
    line-height: unset;
  }
  .chinese .section3 .content1 .step h4 {
    font-size: 5.1vw;
  }
  .section3 .content1 .step1 {
    padding: 42px 4.7vw;
  }
  .section3 .content1 .step1 p:nth-of-type(1) {
    font-size: 2.9vw;
    line-height: unset;
  }
  .section3 .content1 .step1 p:nth-of-type(2) {
    font-weight: 500;
    font-size: 2.08vw;
    line-height: unset;
    color: #7D7D7D;
  }
  .section3 .content1 .step1>div:nth-child(2) {
    border-bottom: 0.57vw dashed #FFC353;
    padding-bottom: 5.2vw;
  }
  .section3 .content1 .step1>div:nth-child(3) {
    padding-top: 3.125vw;
  }
  .section3 .content1 img{
    width: 4.4vw;
  }
  .section3 .content1 .step2 {
    padding: 36px 4.7vw 20px 4.7vw;
  }
  .section3 .content1 .step2 p {
    font-size: 3.2vw;
    line-height: unset;
  }
  .section3 .content1 .step2 h5 {
    font-size: 2.2vw;
    line-height: unset;
  }
  .section3 .content1 .step2 ol {
    padding-left: 2.6vw;
  }
  .section3 .content1 .step2 li {
    font-size: 1.9vw;
    line-height: unset;
  }
  .section3 .content2 {
    padding: 4.17vw 4.69vw 3.125vw 4.69vw;
    gap: 4.95vw;
    border-radius: 3.5vw;
  }
  .section3 .content2 h3 {
    font-size: 3.6vw;
    line-height: unset;
  }
  .chinese .section3 .content2 h3 {
    font-size: 5.2vw;
  }
  .section3 .content2 .bgc1, .section3 .content2 .bgc3 {
    padding: 0.8vw 2.6vw;
    border-radius: 7.57vw;
    font-size: 3.2vw;
    line-height: unset;
    white-space: nowrap;
  }
  .section3 .content2>div {
    gap: 3.9vw;
  }
  .section3 .content2>div>div {
    gap: 4.17vw;
  }
  .section3 .content3 {
    border-radius: 3.5vw;
    padding: 5.6vw 4.7vw;
    gap: 7vw;
  }
  .section3 .content3 h3 {
    font-size: 3.35vw;
    line-height: unset;
  }
  .chinese .section3 .content3 h3 {
    font-size: 4.19vw;
  }
  .section3 .content3 h3 span {
    font-size: 3vw;
    line-height: unset;
  }
  .chinese .section3 .content3 h3 span {
    font-size: 3.8vw;
  }
  .section3 .content3>div .bgc2 {
    padding: 2.34vw 4vw;
  }
  .section3 .content3>div .bgc2 a {
    font-size: 3.65vw;
  }
  .section3 .content3>div .bgc2 img{
    width: 8.72vw;
  }
  .section3 .content3 > div .bgc2 .imgBox {
    bottom: -5.6vw;
    right: -1.04vw;
  }
  .section3_1 .content {
    flex-flow: column;
    margin-bottom: 4.7vw;
  }
  .section3_1 .content .imgBox {
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 22px;
    transform: rotate(90deg);
  }
  .section3_1 .content .step {
    border-radius: 2.34vw;
    padding: 5vw 2.05vw 3.36vw 2.05vw;
  }
  .section3_1 img{
    width: 20px;
  }
  .section3_1 .textBox {
    font-size: 2.6vw;
    line-height: unset;
  }
  .section4 {
    padding-top: 80px;
  }
  .section4 .content {
    padding: 0 4.7vw;
  }
  .section4 .content .prize {
    height: 8.33vw;
    border-radius: 6.25vw;
    padding: 0 7vw 0 3.9vw;
    gap: 5.1vw;
  }
  .chinese .section4 .content .prize {
    height: 8.33vw;
    border-radius: 6.25vw;
    padding: 0 1.56vw 0 3.9vw;
    gap: 2vw;
  }
  .section4 .content .prize .bgc1 {
    width: 18vw;
    padding: 0.6vw 0;
    border-radius: 9.8vw;
    font-size: 3vw;
  }
  .section4 .content .prize:last-child .bgc1 {
    border-radius: 2.08vw;
    font-size: 2.6vw;
  }
  .chinese .section4 .content .prize:last-child .bgc1 {
    width: 18vw;
    padding: 0.6vw 0;
    border-radius: 9.8vw;
    font-size: 3vw;
  }
  .section4 .content .prize>div:nth-child(2) {
    font-size: 2.6vw;
    line-height: unset;
  }
  .chinese .section4 .content .prize>div:nth-child(2) span {
    font-size: 2.2vw;
  }
  .section4 .content .prize .qty {
    width: 8vw;
    font-size: 2.34vw;
  }
  .chinese .section4 .content .prize .qty {
    font-size: 2.1vw;
  }
  .section4 .content .prize:last-child {
    height: 10.4vw;
  }
  .chinese .section4 .content .prize:last-child {
    height: 8.33vw;
  }
  .section5 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .section5 .content2 {
    margin-bottom: 9.9vw;
  }
  .section5 .bgc2 {
    padding: 1.56vw 3.65vw;
    border-radius: 8.6vw;
    font-size: 3.65vw;
  }
  .chinese .section5 .bgc2 {
    font-size: 4.3vw;
  }
  .section5 p {
    font-size: 4.17vw;
    line-height: unset;
  }
  .section5 .modal .textBox p .span2{
    font-size: 2.21vw;
    line-height: 3.78vw;
  }
  .section5 .modal .textBox p .span3{
    font-size: 2.54vw;
    line-height: 3.78vw;
  }
  /* .section5 p br{
    display: none;
  } */
  .section5 .bgc1 {
    padding: 1.3vw 4.56vw;
    border-radius: 7.3vw;
    font-size: 3.9vw;
  }
  .chinese .section5 .bgc1 {
    font-size: 4.56vw;
  }
  .section5 .modal{
    padding: 3.9vw;
    margin-top: 5.2vw;
  }
  .section5 .modal .textBox p{
    font-size: 2.6vw;
    font-weight: 500;
    line-height: 4.43vw;
  }
  .section5 .close {
    margin-bottom: 2.6vw;
    padding: 1vw 2.1vw;
    border-radius: 2.6vw;
    font-size: 2.1vw;
  }


  .section6 .mainTitleBox{
    margin-bottom: 4.4vw;
  }
  .section6 .mainTitleBox h2{
    font-size: 3.9vw;
    line-height: 5.85vw;
  }
  .chinese .section6 .mainTitleBox h2 {
    font-size: 3.9vw;
    line-height: 5.85vw;
  }
  .section6 .content h3{
    font-size: 2.7vw;
    line-height: 4vw;
  }
  .section6 .content h4{
    font-size: 2.6vw;
    line-height: 3.9vw;
  }
  .section6 .content p{
    font-size: 2.6vw;
    line-height: 3.9vw;
  }
  .section6 .content .text1{
    font-size: 2.1vw;
  }
  .section6 .content .text2{
    font-size: 2.3vw;
  }
  .section6 .content .text3{
    font-size: 2.2vw;
  }
  .section6 .content>div{
    padding-top: 2.5vw;
    padding-bottom: 2.1vw;
  }
  .section6 .content>div:first-child{
    gap: 6.1vw;
  }
  .section6 .content .marginText{
    margin-bottom: 1.8vw;
  }
  .section6 .content .logoBox,
  .section6 .content .logoBox>div{
    gap: 1.56vw;
  }

}