/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
svg {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

html {
  font-size: 10px;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: "Noto Sans KR", "Malgun Gothic", sans-serif;
  background-color: #000;
  background-color: #0f1012;
  color: #f2f2f2;
}
:lang(jp),
:lang(ja-JP) {
  font-family: "Noto Sans JP", sans-serif;
}
:lang(zh),
:lang(zh-CN) {
  font-family: "Noto Sans TC", sans-serif;
}

:lang(zh) *,
:lang(zh-CN) *,
:lang(jp) *,
:lang(ja-JP) * {
  word-break: break-all !important;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  word-break: break-word;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: sans-serif;
  font-size: 1em;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bold;
}

img {
  border-style: none;
  max-width: 100%;
  vertical-align: middle;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  -webkit-appearance: none;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */

textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/**
 * Add the correct display in IE 10.
 */

[hidden] {
  display: none;
}

.hidden {
  position: fixed !important;
  top: -999vw;
}

.blind {
  overflow:hidden;
  position: absolute;
  clip:rect(0 0 0 0);
  width:1px;
  height:1px;
  margin:-1px;
}

#root {
  overflow: hidden;
  padding-top: 9rem;
}
.line-banner-active #root {
  padding-top: 15.5rem;
}
html.modal-open {
  overflow: hidden;
}
html.modal-open #root > header,
html.modal-open body {
  overflow-y: scroll;
}

.Toastify {
  font-size: 1.6em;
}
.Toastify .Toastify__toast {
}
.Toastify .Toastify__toast--rtl {
}
.Toastify .Toastify__toast--dark {
}
.Toastify .Toastify__toast--default {
}
.Toastify .Toastify__toast--info {
}
.Toastify .Toastify__toast--success {
}
.Toastify .Toastify__toast--warning {
  background-color: #ffb500;
}
.Toastify .Toastify__toast--error {
}
.Toastify .Toastify__toast-body {
  font-size: 16px;
}

@media (max-width: 1024px) {
  html {
    font-size: 8px;
  }
}

#launcher {
  bottom: 12rem !important;
}

@media (max-width: 767px) {
  html {
    /* font-size: 6px; */
    font-size: 7px;
  }
}

/* @media (max-width: 375px) {
  html {
    font-size: 6px;
  }
} */

@media (max-width: 1024px) {
  body.nav-active {
    overflow: hidden;
  }
  body.nav-active .nav {
    right: 0;
  }
  body.nav-active .nav-close-layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.6;
  }
}

#root .plyr--full-ui input[type="range"] {
  color: #ffb500;
}
#root .plyr--video .plyr__control.plyr__tab-focus,
#root .plyr--video .plyr__control:hover,
#root .plyr--video .plyr__control[aria-expanded="true"] {
  background-color: rgba(226, 107, 19, 0.8);
}
#root
  .plyr__menu__container
  .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
  background-color: rgba(255, 168, 18, 0.8);
}